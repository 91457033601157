import React, { FC } from "react";
import { SectionComponent, SectionContext } from "./types";
import { Wysiwyg } from "./sections/wysiwyg";
import { AreaDetail } from "./sections/area-detail";
import { Gallery } from "./sections/gallery";

interface Props {
    data: any;
    sections?: SectionComponent[];
    context?: SectionContext;
}

const ALL_SECTIONS: SectionComponent[] = [Wysiwyg, AreaDetail, Gallery];

const UNKNOWN_SECTION: SectionComponent = (props) => {
    // eslint-disable-next-line no-console
    console.warn("Unknown section", props);
    return null;
};

UNKNOWN_SECTION.type = "unknown-section";

export const SectionRenderer: FC<Props> = (props) => {
    const { data = [], sections = ALL_SECTIONS, context = {} } = props;
    return (
        <>
            {data.map((sectionData: any, index: number) => {
                const Section: SectionComponent = sections.find((s) => s.type === sectionData.type) ?? UNKNOWN_SECTION;
                return <Section data={sectionData} context={context} index={index} key={index} />;
            })}
        </>
    );
};
