import { Leaf } from "./leaf";
import { isLeaf } from "./type-guards";
import { RenderComponents } from "./types";
import { UxfBlockElement } from "@uxf/wysiwyg/types";

interface ElementProps {
    value: UxfBlockElement;
    components: RenderComponents;
}

export function Element(props: ElementProps) {
    const children = props.value.children.map((item, index) => {
        if (isLeaf(item)) {
            return <Leaf key={index} value={item} components={props.components} />;
        }

        return <Element key={index} value={item} components={props.components} />;
    });

    const { ...components } = props.components;

    const Component: any = components[props.value.type as keyof typeof components];

    return <Component {...props.value}>{children}</Component>;
}
