import {} from "@uxf/wysiwyg";
import React from "react";
import { ContentRenderer } from "@shared/wysiwyg/content-renderer";

export interface WysiwygProps {
    className?: string;
    data: any;
}

export function Wysiwyg(props: WysiwygProps) {
    return (
        <div className={`_wysiwyg ${props.className ?? ""}`}>
            {props.data ? <ContentRenderer data={props.data} /> : null}
        </div>
    );
}
