import React from "react";
import { Header } from "@shared/components/Header";
import { GetStaticPaths, GetStaticProps, NextPage } from "next";
import { addApolloState, initializeApollo } from "@shared/lib/Apollo";
import {
    WebAreaDocument,
    WebAreaListDocument,
    WebAreaListQuery,
    WebAreaListQueryVariables,
    WebAreaQuery,
    WebAreaQueryVariables,
    useWebAreaQuery,
} from "@gql-schema";
import { AndroidButton } from "@ui/AndroidButton";
import { AppleButton } from "@ui/AppleButton";
import { SectionRenderer } from "@shared/components/section-renderer";
import { AreaInfo } from "@content-zone/components/area-info";
import { resizerImageUrl } from "@uxf/core/utils/resizer";

interface Props {
    seoName: string;
}

export const Page: NextPage<Props> = (props) => {
    const { seoName } = props;
    const { data } = useWebAreaQuery({ variables: { seoName } });

    if (!data) {
        return null;
    }

    const area = data.area;

    return (
        <>
            <div className="max-w-7xl mx-auto">
                <Header />
                <div className="py-12 bg-white">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        {!area.pageContent?.length ? (
                            <>
                                <div className="lg:text-center">
                                    <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                        {area.name}
                                    </h2>
                                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                                        {area.description}
                                    </p>
                                </div>
                                <AreaInfo area={area} />
                            </>
                        ) : (
                            <SectionRenderer data={area.pageContent} context={{ areaSeoName: seoName }} />
                        )}
                        <h2 className="mt-16 mb-4 text-lg leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Seznam skal
                        </h2>
                        <ul className="divide-y divide-gray-200">
                            {data.area.sectors.map((sector) => (
                                <li key={sector.id}>
                                    <a className="block hover:bg-gray-50">
                                        <div className="flex items-center py-4">
                                            {sector.image && (
                                                <img
                                                    src={resizerImageUrl(sector.image, 50, 50) ?? ""}
                                                    className="h-12 w-12 rounded-md"
                                                />
                                            )}
                                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between pl-4">
                                                <div className="truncate">
                                                    <div className="flex text-md">
                                                        <p className="truncate font-medium text-primary-600">
                                                            {sector.name}
                                                        </p>
                                                    </div>
                                                    <div className="mt-2 flex">
                                                        <div className="flex items-center text-sm text-gray-500">
                                                            <p>
                                                                {sector.routes.length} cest, {sector.approachTime} minut
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ml-5 flex-shrink-0" />
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="bg-gray-200 bg-opacity-25" id="download-app">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                        <h2 className="max-w-md mx-auto text-3xl font-extrabold text-primary-600 text-center lg:max-w-xl lg:text-left">
                            Mobilní aplikace s průvodcem ke stažení
                        </h2>
                        <div className="flow-root self-center mt-8 lg:mt-0">
                            <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
                                <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                                    <AndroidButton />
                                    <AppleButton />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative bg-white pt-16 pb-32 overflow-hidden">
                <div className="relative">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:pb-16 lg:pt-8 lg:max-w-none lg:mx-0 lg:px-0">
                            <div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                        Co je OpenTopo
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Opentopo je chytrý otevřený lezecký topoprůvodce do mobilu. V Opentopu najdete
                                        nejen fotografie stěn se zakreslenými cestami, ale také mapu s lokací
                                        jednotlivých věží či bouldrů a popis oblastí včetně přístupu a hlavních
                                        vlastností každé oblasti. To vše funguje nejen v online, ale i v offline režimu.
                                    </p>
                                    <div className="mt-6">
                                        <a
                                            href="#download-app"
                                            className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700"
                                        >
                                            Stáhnout aplikaci
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0">
                            <div className="flex justify-center items-center h-full">
                                <img
                                    className="w-60"
                                    src="https://opentopo.app/images/telefony-58.png"
                                    alt="Inbox user interface"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
    const apolloClient = initializeApollo();

    const seoName = ctx.params?.areaSeoName as string;

    try {
        await apolloClient.query<WebAreaQuery, WebAreaQueryVariables>({
            query: WebAreaDocument,
            variables: { seoName },
        });

        return addApolloState(apolloClient, {
            props: { seoName },
            revalidate: 60,
        });
    } catch (e) {
        return {
            props: {},
            revalidate: 10,
        };
    }
};

export const getStaticPaths: GetStaticPaths = async () => {
    const apolloClient = initializeApollo();

    try {
        const { data } = await apolloClient.query<WebAreaListQuery, WebAreaListQueryVariables>({
            query: WebAreaListDocument,
            variables: {},
        });

        return {
            paths: data.areaList.map((a) => ({ params: { areaSeoName: a.seoName } })),
            fallback: true, // false or 'blocking'
        };
    } catch (e) {
        return {
            paths: [],
            fallback: true, // false or 'blocking'
        };
    }
};
