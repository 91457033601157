import React from "react";
import { Wysiwyg } from "@shared/components/wysiwyg";
import { SectionComponent } from "../types";
import { AreaInfo } from "@content-zone/components/area-info";
import { useWebAreaQuery } from "@gql-schema";

export const AreaDetail: SectionComponent = (props) => {
    const { data, context } = props;
    const { data: areaData } = useWebAreaQuery({ variables: { seoName: context.areaSeoName ?? "" } });

    return (
        <>
            <Wysiwyg data={data.content} />
            {areaData && <AreaInfo area={areaData.area} />}
        </>
    );
};

AreaDetail.type = "area-detail";
