
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[areaSeoName]",
      function () {
        return require("private-next-pages/[areaSeoName]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[areaSeoName]"])
      });
    }
  