import React from "react";
import { SectionComponent } from "../types";
import { ImageGallery } from "@uxf/ui/image-gallery";
import { resizerImageUrl } from "@uxf/core/utils/resizer";

export const Gallery: SectionComponent = (props) => {
    return (
        <ImageGallery>
            <div className="flex flex-row justify-content-between gap-y-2 gap-x-2 mt-12 overflow-x-auto">
                {props.data?.content?.images?.map((image: any) => (
                    <ImageGallery.Image
                        key={image.uuid}
                        className="h-72 w-72 cursor-pointer object-cover"
                        src={resizerImageUrl(image, 1600, 1600, { fit: "inside" }) ?? ""}
                    />
                ))}
            </div>
        </ImageGallery>
    );
};

Gallery.type = "gallery";
