import { Element } from "./element";
import { Leaf } from "./leaf";
import { ContentRendererProps, RenderComponents } from "./types";
import { cx } from "@uxf/core/utils/cx";
import { WysiwygContent } from "@uxf/wysiwyg";
import { isLeaf } from "./type-guards";
import { Image } from "@ui/Image/Image";
import React from "react";

const DEFAULT_RENDER_COMPONENTS: RenderComponents = {
    strong: (props) => <strong>{props.children}</strong>,
    underline: (props) => <u>{props.children}</u>,
    italic: (props) => <em className="text-gray-muted">{props.children}</em>,
    highlight: (props) => <mark>{props.children}</mark>,
    h1: (props) => <h1 className="uxf-typo-h1">{props.children}</h1>,
    h2: (props) => <h2 className="uxf-typo-h2">{props.children}</h2>,
    h3: (props) => <h3 className="uxf-typo-h3">{props.children}</h3>,
    h4: (props) => <h4 className="uxf-typo-h4">{props.children}</h4>,
    h5: (props) => <h5 className="uxf-typo-h5">{props.children}</h5>,
    h6: (props) => <h6 className="uxf-typo-h6">{props.children}</h6>,
    ol: (props) => <ol className="ml-4 list-decimal">{props.children}</ol>,
    ul: (props) => <ul className="ml-4 list-disc">{props.children}</ul>,
    li: (props) => <li>{props.children}</li>,
    lic: (props) => <div>{props.children}</div>,
    link: (props) => (
        <a className="underline hover:text-gray-600" href={props.url} target={props.target}>
            {props.children}
        </a>
    ),
    paragraph: (props) => <p className="pb-2 text-xl text-gray-500">{props.children}</p>,
    image: (props) => (
        <div className="py-4">
            <Image className="rounded-lg h-96 w-auto mx-auto object-cover" src={props.file} height={1024} />
            {props.caption && <div className="text-center text-gray-400 text-sm mt-1">{props.caption}</div>}
        </div>
    ),
    custom: {},
};

function contentWithoutLastEmptyParagraph(data: WysiwygContent) {
    const lastNode = data.slice(-1)[0];

    if (lastNode.type === "paragraph" && lastNode.children.length === 1 && lastNode.children[0].text === "") {
        return data.slice(0, -1);
    }

    return data;
}

export const ContentRenderer = (props: ContentRendererProps) => {
    if (!props.data) {
        return null;
    }

    return (
        <div className={cx("uxf-wysiwyg-renderer", props.className)} style={props.style}>
            {contentWithoutLastEmptyParagraph(props.data).map((item, index) => {
                if (isLeaf(item)) {
                    return <Leaf key={index} value={item} components={props.components ?? DEFAULT_RENDER_COMPONENTS} />;
                }

                return <Element key={index} value={item} components={props.components ?? DEFAULT_RENDER_COMPONENTS} />;
            })}
        </div>
    );
};
