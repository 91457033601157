import React from "react";
import { Wysiwyg as SharedWysiwyg } from "@shared/components/wysiwyg";
import { SectionComponent } from "../types";

export const Wysiwyg: SectionComponent = (props) => {
    const { data } = props;
    return <SharedWysiwyg data={data.content} />;
};

Wysiwyg.type = "wysiwyg";
