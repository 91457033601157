import {
    NoSymbolIcon as BanIcon,
    MapPinIcon as LocationMarkerIcon,
    SunIcon,
    LifebuoyIcon as SupportIcon,
    UserGroupIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { WebAreaFragment } from "@gql-schema";

export interface AreaInfoProps {
    area: WebAreaFragment;
}

export const AreaInfo: React.FC<AreaInfoProps> = (props) => {
    const { area } = props;
    return (
        <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                {area.approach && (
                    <div className="relative">
                        <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                                <LocationMarkerIcon className="h-6 w-6" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Poloha a přístup</p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">{area.approach}</dd>
                    </div>
                )}
                {area.climbingRestrictions && (
                    <div className="relative">
                        <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                                <BanIcon className="h-6 w-6" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Omezení lezení</p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">{area.climbingRestrictions}</dd>
                    </div>
                )}
                {area.typeOfProtection && (
                    <div className="relative">
                        <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                                <SupportIcon className="h-6 w-6" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Typ jištění</p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">{area.typeOfProtection}</dd>
                    </div>
                )}
                {area.orientation && (
                    <div className="relative">
                        <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                                <SunIcon className="h-6 w-6" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Orientace</p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">{area.orientation}</dd>
                    </div>
                )}
                {area.babyFriendly && (
                    <div className="relative">
                        <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                                <UserGroupIcon className="h-6 w-6" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Vhodné s dětmi</p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">{area.babyFriendly}</dd>
                    </div>
                )}
            </dl>
        </div>
    );
};
