import React, { useEffect } from "react";

type Src = {
    namespace?: string | undefined;
    uuid: string;
    extension: string;
};

type ImageProps = {
    src?: Src | null;
    className?: string;
    size?: number;
    width?: number;
    height?: number;
};

export const Image: React.FC<ImageProps> = (props) => {
    const { src, size, height, width, ...imgProps } = props;

    useEffect(() => {
        if (size && (width || height)) {
            // eslint-disable-next-line no-console
            console.log("Props 'size' is defined with 'width' or 'height' together.");
        }
    }, [size, width, height]);

    if (!src) {
        return null;
    }

    const url =
        size || width || height
            ? [
                  "/generated",
                  src.namespace ?? "default",
                  src.uuid.charAt(0),
                  src.uuid.charAt(1),
                  `${src.uuid}_${width ?? size ?? "x"}_${height ?? size ?? "x"}_c.${src.extension}`,
              ].join("/")
            : [
                  "/upload",
                  src.namespace ?? "default",
                  src.uuid.charAt(0),
                  src.uuid.charAt(1),
                  `${src.uuid}.${src.extension}`,
              ].join("/");
    return <img src={url} {...imgProps} />;
};
