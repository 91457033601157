import { StringHelper } from "@shared/utils/string-helper";
import { RichText } from "@uxf/wysiwyg/types";
import { ReactNode } from "react";
import { RenderComponents } from "./types";

interface LeafProps {
    value: RichText;
    components: RenderComponents;
}

export function Leaf(props: LeafProps) {
    let content: ReactNode = <>{StringHelper.nl2br(props.value.text)}</>;

    if (props.value.text === "") {
        return <>&#65279;</>;
    }

    if (props.value.bold) {
        content = <props.components.strong>{content}</props.components.strong>;
    }
    if (props.value.italic) {
        content = <props.components.italic>{content}</props.components.italic>;
    }
    if (props.value.underline) {
        content = <props.components.underline>{content}</props.components.underline>;
    }
    if (props.value.highlight) {
        content = <props.components.highlight>{content}</props.components.highlight>;
    }

    return content;
}
