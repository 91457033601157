import { Fragment, ReactElement } from "react";
import { renderToString } from "react-dom/server";

const camelCaseToDash = (content: string): string => {
    return content.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};

/* https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1 */
const slugify = (content: string): string => {
    const a = "àáäâãåăæąçćčđďèéěėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;";
    const b = "aaaaaaaaacccddeeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    return content
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
};

const reactElementToInnerString = (value: ReactElement): string => {
    return renderToString(value).replace(/(<([^>]+)>)/gi, "");
};

const escapeQuotes = (value: string): string => {
    return value.replace(/\x22/g, "\\\x22");
};

const capitalize = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);

const nl2br = (str: string) =>
    str.split("\n").map(function (item, i) {
        if (i === 0) {
            return item;
        }

        return (
            <Fragment key={i}>
                <br />
                {item}
            </Fragment>
        );
    });

export const StringHelper = {
    camelCaseToDash,
    capitalize,
    escapeQuotes,
    reactElementToInnerString,
    slugify,
    nl2br,
};
